.download-header {
  margin: 0;
  padding: 0;
}

.qr-code {
  margin: 0;
  padding: 0;
  width: 350px;
}

.download-box {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 15px 0px;
}