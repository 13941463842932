.share-link {
  width: 380px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share-link-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.share-link-loading {
  margin: 50px auto 0 auto;

  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.share-link-logo {
  margin-top: 40px;
  width: 110px;
}

.social-share-box {
  padding: 40px;
  margin: 40px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px 0px;

  background: #FFFFFF;
  box-shadow: 0px 20px 32px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.share-link-join {
  margin-top: 0px;
  margin-bottom: 15px;
  padding: 0;
}

.login-button {
  height: 40px;

  background-color: #FFF;
  color: #000;

  display: flex;
  align-items: center;

  font-family: 'Modesto Text';
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;

  border: 1px solid #d4d4d4;
  border-radius: 3px;
}

.login-button:hover{
  background: #F8F8F8;
}

.login-text {
  margin: auto;
}

.load-box {
  margin-top: 50px;

  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}